import { IAPlayAdmin } from "intraactive-sdk-ui/dist/playAdmin";
import * as microsoftTeams from "@microsoft/teams-js";
import { useState } from "react";
import { Environment, GetLanguageForLocale, IsInTeams, MaximumTries } from "./lib/constants";
import { useEffect } from "react";
import { fetchData } from "./lib/fetchData";
import jwt_decode from "jwt-decode";

interface PlayAdminProps {
  isDarkMode?: boolean;
}

interface IdentityToken {
  exp: number;
}

export function PlayAdmin(props: PlayAdminProps) {
  const [renderApp, setRenderApp] = useState<boolean>(false);
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [authTokenDelta, setAuthTokenDelta] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [teamsContextLoadingInitiated, setTeamsContextLoadingInitiated] = useState<boolean>(false);
  const [consentIsGiven, setConsentIsGiven] = useState<boolean>(false);
  const [displayConsentButton, setDisplayConsentButton] = useState<boolean>(false);
  const [sharepointRootSite, setSharepointRootSite] = useState<string | undefined>(undefined);

  const authenticate = () => {
    console.debug("PLAYAPP - authentication.authenticate - CALLED");

    microsoftTeams.authentication.authenticate({
      url: window.location.origin + "/auth-start.html?clientId=" + process.env.REACT_APP_CLIENT_ID,
      width: 600,
      height: 535,
      successCallback: result => {
        console.debug("PLAYAPP - authentication.authenticate - result - ", result);
        setIsLoading(true);
        // wait until consent is verified before showing the app
        let tries = 0;
        const verifyConstentInterval = setInterval(() => {
          if (tries >= MaximumTries) {
            clearInterval(verifyConstentInterval);
          }
          fetchData<{ consentGiven: boolean }>('verify-consent', authToken as string)
            .then(({ consentGiven }) => {
              console.debug("PLAYAPP - check MS auth - consentGiven -", consentGiven);
              if (consentGiven) {
                setIsLoading(false);
                setConsentIsGiven(true);
                setDisplayConsentButton(false);
                clearInterval(verifyConstentInterval);
              }
            }).finally(() => {
              tries++;
            });
        }, 5000);
      },
      failureCallback: reason => {
        console.error("PLAYAPP - authentication.authenticate - error - ", reason);
        setConsentIsGiven(false);
        setDisplayConsentButton(false);
      }
    });
  };

  var getAuthToken = (verifyConsent: boolean) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (authToken) => {
        const exp = jwt_decode<IdentityToken>(authToken).exp;
        const now = parseInt((new Date().getTime()/1000).toString());
        const delta = exp - now;
        console.debug("PLAYAPP - getAuthToken - Success (token): ", authToken);
        console.debug("PLAYAPP - getAuthToken - Success (exp): ", exp);
        console.debug("PLAYAPP - getAuthToken - Success (now): ", now);
        console.debug("PLAYAPP - getAuthToken - Success (delta -> exp - now): ", delta);
        setAuthToken(authToken);
        setAuthTokenDelta(delta);
        if (!renderApp) {
          setRenderApp(true);
        }
        if (verifyConsent) {
          fetchData<{ consentGiven: boolean }>('verify-consent', authToken)
            .then(({ consentGiven }) => {
              console.debug("PLAYAPP - verifyConsent - consentGiven -", consentGiven);
              if (consentGiven) {
                setConsentIsGiven(true);
                setDisplayConsentButton(false);
              } else {
                setConsentIsGiven(false);
                setDisplayConsentButton(true);
              }
            })
            .catch(error => {
              console.error("PLAYAPP - verifyConsent - Error: ", error);
              setConsentIsGiven(false);
              setDisplayConsentButton(false);
            })
            .finally(() => {
              console.debug("PLAYAPP - loadingComplete")
              setIsLoading(false);
            });
        }
      },
      failureCallback: error => {
        console.error("PLAYAPP - getAuthToken - Error: ", error);
        if (!renderApp) {
          setRenderApp(true);
        }
      }
    });
  }

  useEffect(() => {
    if (!teamsContextLoadingInitiated) {
      setTeamsContextLoadingInitiated(true);
      try {
        microsoftTeams.initialize();
        microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
          console.debug("PLAYAPP - getContext - Success: ", context.tid);
          microsoftTeams.appInitialization.notifySuccess();
          localStorage.setItem("IA_language", GetLanguageForLocale(context.locale));
          setTenantId(context.tid);
          setSharepointRootSite(context.teamSiteDomain);
          getAuthToken(true);
        });
      } catch (error) {
        // Notify that there was a issue while loading the app
        microsoftTeams.appInitialization.notifyFailure({ reason: microsoftTeams.appInitialization.FailedReason.Other, message: "Unable to reach server." });
      }
      
    }
  }, [teamsContextLoadingInitiated]);

  useEffect(() => {
    if (authTokenDelta === undefined) {
      return;
    }

    console.debug("PLAYAPP - useEffect[authTokenDelta] - authTokenDelta: ", authTokenDelta);
    
    if (!authTokenDelta || authTokenDelta <= 0) {
      console.debug("PLAYAPP - useEffect[authTokenDelta] - Calling getAuthToken()");
      getAuthToken(false);
    } else {
      const timer = setTimeout(() => {
        console.debug("PLAYAPP - useEffect[authTokenDelta] - Calling getAuthToken() from within SETTIMEOUT");
        getAuthToken(false);
      }, (authTokenDelta - 30) * 1000);
      return () => clearTimeout(timer);
    }
  }, [authTokenDelta]);

  return (
    <>
      {IsInTeams() ? (
        <>
          {tenantId !== undefined && renderApp && (
            <IAPlayAdmin
              environment={Environment}
              tenantId={tenantId as string}
              sharepointRootSite={sharepointRootSite as string}
              token={authToken as string}
              isTeams={true}
              isLoading={isLoading}
              displayConsentButton={displayConsentButton}
              consentIsGiven={consentIsGiven}
              darkMode={props.isDarkMode}
              authenticate={() => authenticate()}
            />
          )}
        </>
      ) : (
        <IAPlayAdmin
          environment={Environment}
          tenantId="c7392b95-d07b-4653-87a7-6c709f527c37"
          sharepointRootSite="intraactivedev.sharepoint.com"
          token="DEV_TOKEN"
          isTeams={false}
          isLoading={false}
          displayConsentButton={false}
          consentIsGiven={true}
          darkMode={false}
          authenticate={() => {}}
        />
      )}
    </>
  );
}
